import * as React from "react"
import { useEffect } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BannerTop from "../../components/Modules/MediaPush/BannerTop"

import { connect } from "react-redux"

import {getTagsAction, getNoveltiesAction, getFiltersNoveltiesAction} from "../../redux/noveltiesDucks"

//Components
import Main from '../../components/Blog/main'

const IndexPage = ({novelties,tags,dispatch}) => {

  useEffect(() => {
    // dispatch(getNoveltiesAction())
    dispatch(getFiltersNoveltiesAction())
    dispatch(getTagsAction())
  },[])

  return(
  <Layout>
    <Seo title="Blog" />
    <BannerTop page="novedades"/>
    <Main />
  </Layout>
)
}

export default connect(state => ({
  novelties: state.novelties.novelties,
  tags: state.novelties.tags
}),null)(IndexPage);
