import React from 'react'
import { Link } from 'gatsby'
import { getTextShort,getDateTime } from '../helpers/helper.novelty'
import { navigate } from "gatsby"
import {clearNoveltyAction} from '../redux/noveltiesDucks'
import { connect } from 'react-redux'
import IconRightSvg from "../images/svg/icon-right.svg"

const NoveltyCard = (props) => {

    const isHome = props?.isHome
    const {novelty,skeleton} = props

    return  (
        <div class={"card blog-card " + (skeleton && ' skeleton ') + (isHome === true && ' isHome')}>
            <div class="card-image">
                <Link to={"/novedades/" + novelty?.id + "/" + novelty?.slug}>
                    {/* <img src={process.env.GATSBY_SERVER_URL + novelty?.image} alt={novelty?.title} /> */}
                    <img src={novelty?.image} alt="" />
                    {/* <div style={{backgroundImage: "url(" + item.image + ")"}} className="img-novelty"></div> */}
                </Link>
            </div>
            <div class="card-body">
                <h3 class="card-title mb-2">
                    <Link to={"/novedades/" + novelty?.id + "/" + novelty?.slug}>
                        {novelty?.title}
                    </Link>
                </h3>                
                <p class="card-text">{getTextShort(novelty?.headline, 100)}</p> 
                <div class="content-extra d-flex justify-content-between align-items-center mt-3">
                    <Link to={"/novedades/" + novelty?.id + "/" + novelty?.slug} className="btn btn-orange">
                        Leer nota <span class="d-none d-lg-inline ms-1">completa</span>
                        <IconRightSvg class="icon"/>
                    </Link>
                    <p class="card-date">{getDateTime(novelty?.date_published,true)}</p>
                </div>
            </div>
        </div>
    )
}

export default connect(state => ({
  }),null)(NoveltyCard);
